import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/var/www/kecman.dev/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Image = makeShortcode("Image");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`aboutMe`}</h1>
    <Image sx={{
      width: "300px",
      height: "auto",
      borderRadius: "100%",
      float: "right",
      p: 3
    }} src="/images/photo.jpg" mdxType="Image" />
    <p>{`Hi, there! I'm Front-end developer, working full time on Angular applications, and exploring world of programming and javascript in my free time.`}</p>
    <p>{`I've always had a passion for creating small things, around the house, but when I discovered Photoshop, and ability to start from blank canvas and create something awesome. For many
years before going into programming, I developed small Wordpress sites for local businesses and did small graphic design jobs.`}</p>
    <p>{`Having worn many hats in my career between business developement, marketing management, 3D vizuelization, graphic design,
I use my experience to be more than just a coder, and I'm trying to at the same time think as UX designer,
marketing specialist with customers needs and habits on mind.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      